import { createStore } from 'vuex'
import axios from 'axios';


export default createStore({
    state: {
        meta: {
            address: "http://localhost/bowling/php/",
            datum: "2024-11-11",
            bahnen: []
                //address: "http://deluxe.free.nf/php/",
        },
        bestellung: {
            id: "",
            name: "",
            start: "",
            stunden: "",
            end: "",
            personen: "",
            datum: "",
            tel: "",
            notiz: "",
            error: {
                status: false,
                msg: "", //
                checked: false,
                sucStatus: false,
                sucText: ""
            },
            bahn: {
                selected: "",
                one: "bahn",
                two: "bahn",
                three: "bahn",
                four: "bahn",
                five: "bahn",
                six: "bahn",
                seven: "bahn",
                eight: "bahn",
                nine: "bahn",
                ten: "bahn",
            }

        },
        bahnen: []

    },
    getters: {
        mainBestellung: (state) => state.bestellung,
        mainBahnen: (state) => state.bahnen,
        mainMeta: (state) => state.meta,
    },
    mutations: {},
    actions: {

        async mflApi(context, data) {
            const url = context.state.meta.address + data.url + ".php";



            if (data["val"] == 2) {

                const obj = {
                    "bestellung": context.state.bestellung,
                    "bahn": context.state.bahn
                }

                data["input"] = obj;
            } else if (data["val"] == 101) {
                data["input"] = context.state.meta.datum;
            } else {
                data["input"] = context.state.bestellung;
            }

            let payload = JSON.stringify(data);

            return new Promise(function(resolve, reject) {
                axios.post(url, payload)
                    .then(function(response) {

                        if (data.val == 1) {
                            context.state.bahnen = response.data;
                        } else if (data.val == 2) {
                            if (response.data.status == 0) {
                                context.state.bestellung.error.status = true;
                                context.state.bestellung.error.sucStatus = false;
                                context.state.bestellung.error.msg = response.data.msg;
                            } else {

                                context.state.bestellung = {
                                    id: "",
                                    name: "",
                                    start: "",
                                    stunden: "",
                                    end: "",
                                    personen: "",
                                    datum: "",
                                    tel: "",
                                    notiz: "",
                                    error: {
                                        status: false,
                                        msg: "", //
                                        checked: false,
                                        sucStatus: false,
                                        sucText: ""
                                    },
                                };

                                context.state.bahn = {
                                    selected: "",
                                    one: "bahn",
                                    two: "bahn",
                                    three: "bahn",
                                    four: "bahn",
                                    five: "bahn",
                                    six: "bahn",
                                    seven: "bahn",
                                    eight: "bahn",
                                    nine: "bahn",
                                    ten: "bahn",
                                };

                                context.state.bahnen = [];

                                context.state.bestellung.error.status = false;
                                context.state.bestellung.error.sucStatus = true;
                                context.state.bestellung.error.sucText = response.data.msg;
                            }
                        } else if (data.val == 98) {
                            if (response.data.status == 0) {
                                context.state.bestellung.error.status = true;
                                context.state.bestellung.error.msg = response.data.msg;
                                context.state.bestellung.error.sucStatus = false;
                                context.state.bestellung.error.sucText = ""
                            } else {
                                context.state.bestellung.error.status = false;
                                context.state.bestellung.error.status = ""
                                context.state.bestellung.error.sucStatus = true;
                                context.state.bestellung.error.sucText = response.data.msg;
                            }
                        } else if (data.val == 100 || data.val == 101) {
                            context.state.meta.datum = response.data.datum;
                            context.state.meta.bahnen = response.data.list;
                            //    console.log(response.data.list)
                        }
                        // console.log(response.data);
                    })
                    .catch(function(error) {
                        reject(error);
                    })
            })
        },



        // async getTeams(context) {
        //     const url = context.state.meta.address + "getTeams.php";

        //     return new Promise(function(resolve, reject) {
        //         axios.get(url)
        //             .then(function(response) {
        //                 console.log(response.data);
        //                 context.state.teams.allTeams = response.data;
        //                 context.state.teams.rootTeams = response.data;
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },

        // async searchTeams(context, filter) {
        //     const url = context.state.meta.address + "searchTeams.php?term=" + filter;

        //     return new Promise(function(resolve, reject) {
        //         axios.get(url)
        //             .then(function(response) {
        //                 context.state.teams.allTeams = response.data;
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },


        // async getTeamData(context) {
        //     const url = context.state.meta.address + "getTeamData.php?team=" + context.state.teams.selectedId;

        //     return new Promise(function(resolve, reject) {
        //         axios.get(url)
        //             .then(function(response) {
        //                 context.state.teams.roster = response.data.roster;
        //                 context.state.teams.depht = response.data.depht;
        //                 console.log(response.data);
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },

        // async getPlayerStats(context) {
        //     const url = context.state.meta.address + "getPlayerStats.php?id=" + context.state.teams.selectedPlayer.id;

        //     return new Promise(function(resolve, reject) {
        //         axios.get(url)
        //             .then(function(response) {
        //                 console.log(response.data);
        //                 context.state.teams.selectedPlayer.stats = response.data;
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },

        // async getSchedule(context) {
        //     const url = context.state.meta.address + "getSchedule.php?id=" + context.state.teams.selectedId;

        //     return new Promise(function(resolve, reject) {
        //         axios.get(url)
        //             .then(function(response) {
        //                 console.log(response.data);
        //                 context.state.teams.schedule = response.data;
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },

        // async getCoachingStaff(context) {
        //     const url = context.state.meta.address + "getCoachingStaff.php?id=" + context.state.teams.selectedId;

        //     return new Promise(function(resolve, reject) {
        //         axios.get(url)
        //             .then(function(response) {
        //                 console.log(response.data);
        //                 context.state.teams.staff = response.data;
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },


        // async getStandings(context) {
        //     const url = context.state.meta.address + "getStandings.php";

        //     return new Promise(function(resolve, reject) {
        //         axios.get(url)
        //             .then(function(response) {
        //                 console.log(response.data);
        //                 context.state.teams.standings = response.data;
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },



        // async getStatLeaders(context, obj) {
        //     const url = context.state.meta.address + "getStatLeaders.php";
        //     let payload = JSON.stringify(obj);

        //     return new Promise(function(resolve, reject) {
        //         axios.post(url, payload)
        //             .then(function(response) {
        //                 console.log(response.data);
        //                 context.state.league.stats = response.data;
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },

        // async getGameWeek(context, obj) {
        //     const url = context.state.meta.address + "getGameWeek.php";
        //     let payload = JSON.stringify(obj);

        //     return new Promise(function(resolve, reject) {
        //         axios.post(url, payload)
        //             .then(function(response) {
        //                 console.log(response.data);
        //                 context.state.league.gameWeek = response.data;
        //             })
        //             .catch(function(error) {
        //                 reject(error);
        //             })
        //     })
        // },


    },
    modules: {}
})