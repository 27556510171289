<template>
  <div class="mainPage">

    <div class="section">
      <h2 class="header1">Impressum</h2>
    
      <table class="table">
        <tr>
          <th>Unternhemen</th>
          <td>Deluxe Bowling Centre</td>
        </tr>
        <tr>
          <th>Adresse</th>
          <td>Columbiastraße 19, 97688 Bad Kissingen</td>
        </tr>
        <tr>
          <th>Geschäftsführer</th>
          <td>Kais Mansur</td>
        </tr>
        <tr>
          <th>Telefonnummer</th>
          <td>01704618521</td>
        </tr>
        <tr>
          <th>E-Mail</th>
          <td>Kais.Mansur@web.de</td>
        </tr>
      </table>


    </div>

    

  </div>
</template>
    
    <script>
    import { defineComponent } from '@vue/runtime-core'
    import { mapGetters, mapActions } from "vuex";
    
    export default defineComponent({
      setup() {
        
      }, data() {
        return {
      
            filter: ""
          }
        },
       components:{
       },
       computed: {
          ...mapGetters(["mainBestellung", "mainBahnen"]),
       },
       methods:{
           ...mapActions(["mflApi"]),

             
       }
    })
    </script>
    
    <style>
    
    .section{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 80%;
      border: solid black;
      background-color: rgb(212, 194, 88);
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .table{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
  table-layout: fixed;
  text-align: center;
}

.table th, .table td{
  border: 4px solid rgb(212, 194, 88);
  padding: 8px;
}


.table th{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: black;
  color: rgb(212, 194, 88);
}
    
    .input{
      width: 60%;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border: solid 2px black;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    
    
    
    </style>