import { createRouter, createWebHashHistory } from 'vue-router'
import home from '../views/home.vue'
import overview from '../views/overview.vue'
import buchung from '../views/buchung.vue'
import datenschutz from '../views/datenschutz.vue'
import impressum from '../views/impressum.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/overview',
        name: 'overview',
        component: overview
    },
    {
        path: '/buchung',
        name: 'buchung',
        component: buchung
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        component: datenschutz
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: impressum
    }


]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router