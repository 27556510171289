<template>
<div class="main">

  <router-view    />
</div>
</template>

<script>

import { defineComponent } from '@vue/runtime-core'
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  setup() {
    
  },
   components:{
     
   },
   computed: {
     ...mapGetters(["mainBestellung"]),
   },
   methods:{
     ...mapActions(["mflApi"]),
   
   },
  created(){
 
  }
})
</script>

<style scoped>
#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.main{
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  color:   black;
  overflow-y: scroll;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.mainPage{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
}

.subContainer{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  border: solid 2px blue;
  height: 100px;
}

.subRow{
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.site-header{
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  margin-block-start: 0px;
  margin-block-end: 0px;
}


</style>
