<template>
<div class="mainPage">
<h1 class="site-header">Bowling Reservierung</h1>

<div class="bowling">
<input type="text" class="input" placeholder="Name*" v-model="this.mainBestellung.name" />
<input type="tel" class="input" placeholder="Telefon*" v-model="this.mainBestellung.tel"/>
<input type="date" class="input" v-model="this.mainBestellung.datum" />
<input type="time" @change="this.updateTime()" class="input" v-model="this.mainBestellung.start" />

<select class="input" v-model="this.mainBestellung.stunden">
<option value="0.5">30 Minuten</option>
<option value="1">1 Stunde</option>
<option value="1.5">1 Stunde 30 Minuten</option>
<option value="2">2 Stunden</option>
<option value="2.5">2 Stunden 30 Minuten</option>
<option value="3">3 Stunden</option>
<option value="3.5">3 Stunden 30 Minuten</option>
<option value="4">4 Stunden</option>
</select>

<input type="number" class="input" placeholder="Personen*" v-model="this.mainBestellung.personen"/>
<input type="text" class="input" placeholder="Notiz" v-model="this.mainBestellung.notiz"/>

<input type="button" class="button" value="Prüfen" @click="checkInput()"/>

<p class="errorMsg" v-if="this.mainBestellung.error.status == true">{{ this.mainBestellung.error.msg }}</p>
<p class="sucMsg" v-if="this.mainBestellung.error.sucStatus == true">{{ this.mainBestellung.error.sucText }}</p>

<div class="bahnen" v-if="this.mainBestellung.error.checked == true">
<div :class="getClass(bahn)" v-for="bahn in this.mainBahnen" :key="bahn" @click="selectBahn(bahn)">
<p class="bahnText">{{ bahn }}</p>
</div>
</div>


<input type="button" class="button" value="Buchen" @click="buchen()" v-if="this.mainBahnen.length > 0"/>


</div>

<div class="links">
  <p class="link" @click="redirect('datenschutz')">Datenschutz</p>
  <p class="link" @click="redirect('impressum')">Impressum</p>
</div>

</div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  setup() {
    
  }, data() {
    return {
  
        filter: ""
      }
    },
   components:{
   },
   computed: {
      ...mapGetters(["mainBestellung", "mainBahnen"]),
   },
   methods:{
       ...mapActions(["mflApi"]),
       redirect(target){
        this.$router.push(target) 
       },
       buchen(){
        //Algemeine Funktion bauen
        this.mainBestellung.sucStatus = false;
        this.mainBestellung.sucText = "";
        this.mainBestellung.error.status = true;
        this.mainBestellung.error.msg = "";
        
        var error = 0;
        if(this.mainBestellung.name == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie einen Namen an.";
        }
        else if(this.mainBestellung.tel == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie eine Telefonnummer an.";
        }
        else if(this.mainBestellung.date == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie ein Datum an.";
        }
        else if(this.mainBestellung.start == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie eine Uhrzeit an.";
        }
        else if(this.mainBestellung.stunden == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie an, wie lange Sie spielen wollen.";
        }
        else if(this.mainBestellung.personen == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie die Anzahl der Personen an.";
        }
        else{
          this.mainBestellung.error.msg = "";
        }

        if(error == 1){
          this.mainBestellung.error.status = true;
        }
        else{
          this.mainBestellung.error.status = false;

          const data = {
            url: "bookBahn",
            val: 2
          }

          this.mflApi(data);
        }

       },
       checkInput(){
        var error = 0;
        console.log(this.mainBahnen)

        this.mainBestellung.sucStatus = false;
        this.mainBestellung.sucText = "";
        this.mainBestellung.error.status = true;
        this.mainBestellung.error.msg = "";

        if(this.mainBestellung.name == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie einen Namen an.";
        }
        else if(this.mainBestellung.tel == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie eine Telefonnummer an.";
        }
        else if(this.mainBestellung.date == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie ein Datum an.";
        }
        else if(this.mainBestellung.start == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie eine Uhrzeit an.";
        }
        else if(this.mainBestellung.stunden == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie an, wie lange Sie spielen wollen.";
        }
        else if(this.mainBestellung.personen == ""){
          error = 1;
          this.mainBestellung.error.msg = "Bitte geben Sie die Anzahl der Personen an.";
        }
        else{
          this.mainBestellung.error.msg = "";
        }

        if(error == 1){
          this.mainBestellung.error.status = true;
        }
        else{
          this.mainBestellung.error.status = false;
          this.mainBestellung.error.checked = true;

          const data = {
            url: "checkBahn",
            val: 1
          }

          this.mflApi(data);
        }


        
       },
       selectBahn(bahn){
        this.mainBestellung.bahn.selected = bahn;

        this.mainBestellung.bahn.one = "bahn";
        this.mainBestellung.bahn.two = "bahn";
        this.mainBestellung.bahn.three = "bahn";
        this.mainBestellung.bahn.four = "bahn";
        this.mainBestellung.bahn.five = "bahn";
        this.mainBestellung.bahn.six = "bahn";
        this.mainBestellung.bahn.seven = "bahn";
        this.mainBestellung.bahn.eight = "bahn";
        this.mainBestellung.bahn.nine = "bahn";
        this.mainBestellung.bahn.ten = "bahn";

        if(bahn == 1){
          this.mainBestellung.bahn.one = "bahn selected";
        }
        else if(bahn == 2){
          this.mainBestellung.bahn.two = "bahn selected";
        }
        else if(bahn == 3){
          this.mainBestellung.bahn.three = "bahn selected";
        }
        else if(bahn == 4){
          this.mainBestellung.bahn.four = "bahn selected";
        }
        else if(bahn == 5){
          this.mainBestellung.bahn.five = "bahn selected";
        }
        else if(bahn == 6){
          this.mainBestellung.bahn.six = "bahn selected";
        }
        else if(bahn == 7){
          this.mainBestellung.bahn.seven = "bahn selected";
        }
        else if(bahn == 8){
          this.mainBestellung.bahn.eight = "bahn selected";
        }
        else if(bahn == 9){
          this.mainBestellung.bahn.nine = "bahn selected";
        }
        else if(bahn == 10){
          this.mainBestellung.bahn.ten = "bahn selected";
        }
       },

       getClass(bahn){
        if(bahn == 1){
          return this.mainBestellung.bahn.one;
        }
        else if(bahn == 2){
          return this.mainBestellung.bahn.two;
        }
        else if(bahn == 3){
          return this.mainBestellung.bahn.three;
        }
        else if(bahn == 4){
          return this.mainBestellung.bahn.four;
        }
        else if(bahn == 5){
          return this.mainBestellung.bahn.five;
        }
        else if(bahn == 6){
          return this.mainBestellung.bahn.six;
        }
        else if(bahn == 7){
          return this.mainBestellung.bahn.seven;
        }
        else if(bahn == 8){
          return this.mainBestellung.bahn.eight;
        }
        else if(bahn == 9){
          return this.mainBestellung.bahn.nine;
        }
        else if(bahn == 10){
          return this.mainBestellung.bahn.ten;
        }
       },

       updateTime(){
        var time = this.mainBestellung.start
        let [hours, minutes] = time.split(':').map(Number);

// Determine the rounded time based on the minutes value
if (minutes < 8) {
    minutes = 0;
} else if (minutes >= 8 && minutes <= 15) {
    minutes = 15;
} else if (minutes >= 16 && minutes <= 20) {
    minutes = 15;
} else if (minutes >= 21 && minutes <= 36) {
    minutes = 30;
} else if (minutes >= 37 && minutes <= 49) {
    minutes = 45;
} else if (minutes >= 50) {
    minutes = 0;
    hours = (hours + 1) % 24; // Increment hour, wrap around to 0 if it exceeds 23
}

// Format the time as HH:MM with leading zeros if needed
const roundedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
this.mainBestellung.start = roundedTime;
}



   }
})
</script>

<style>

.bowling{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  border: solid black;
  background-color: rgb(212, 194, 88);
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}


.input{
  width: 60%;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  border: solid 2px black;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.button{
  width: 40%;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  border: solid 2px black;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: black;
  color: rgb(212, 194, 88);
}

.button:hover{
  transform: scale(1.05);
  background-color: rgb(255, 255, 255);
}

.errorMsg{
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: rgb(189, 30, 30);
}

.sucMsg{
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: rgb(18, 89, 13);
}

.bahnen{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.bahn{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 50px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: solid 2px black;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
  color: rgb(212, 194, 88);

}

.bahn:hover{
  transform: scale(1.05);
  background-color: white;
  color: black;
}

.bahnText{
  width: 100%;
  font-size: 24px;
  font-weight: bold;

  text-align: center;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.selected{
  background-color: rgb(117, 214, 117);
  color: white;
}


.links{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.link{
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  max-width: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.link:hover{
  text-decoration: underline;
}

</style>