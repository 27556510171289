<template>
  <div class="mainPage">
    <h1 class="site-header">Datenschutzerklärung</h1>

    <div class="section">
      <h2 class="header1">1. Allgemeine Hinweise</h2>
      <p class="text">
        Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen.
        Diese Datenschutzerklärung informiert Sie darüber, welche personenbezogenen Daten wir im Rahmen Ihrer
        Reservierung
        erheben, wie wir diese verarbeiten und welche Rechte Sie als betroffene Person haben.
      </p>
    </div>

    <div class="section">
      <h2 class="header1">2. Verantwortliche Stelle
      </h2>
      <p class="text">
        Verantwortlich für die Datenverarbeitung auf dieser Website ist:<br><br>
        <b>Deluxe Bowling Centre</b><br><br>
        <b>Columbiastraße 19, 97688 Bad Kissingen</b><br><br>
        <b>01704618521</b><br><br>
        <b>Kais.Mansur@web.de</b><br><br>
        <b>Angelika Sawas</b><br><br>
      </p>
    </div>

    <div class="section">
      <h2 class="header1">3. Erhobene Daten
      </h2>
      <p class="text">
        Im Rahmen der Nutzung unseres Kontaktformulars zur Reservierung einer Bowlingbahn erheben wir die folgenden
        personenbezogenen Daten:<br><br>
        <b>Name</b><br><br>
        <b>Telefonnummer</b><br><br>
        <b>Notiz (optionales Feld für zusätzliche Daten)</b><br><br>
      </p>
    </div>

    <div class="section">
      <h2 class="header1">4. Zweck der Datenerhebung
      </h2>
      <p class="text">
        Zweck der Datenverarbeitung
        Die Erhebung und Verarbeitung der genannten Daten erfolgt ausschließlich zu folgenden Zwecken:<br><br>
        <b>Bearbeitung und Verwaltung Ihrer Reservierungsanfrage</b><br><br>
        <b>Kontaktaufnahme bei Rückfragen oder Bestätigungen</b><br><br>
        <b>Sicherstellung eines reibungslosen Ablaufs Ihrer Reservierung</b><br><br>
      </p>
    </div>

    <div class="section">
      <h2 class="header1">5. Rechtsgrundlage der Verarbeitung
      </h2>
      <p class="text">
        Die Verarbeitung Ihrer Daten erfolgt gemäß <b>Art. 6 Abs. 1 lit. b DSGVO</b> zur Erfüllung eines Vertrags oder
        zur
        Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen.<br><br>

      </p>
    </div>

    <div class="section">
      <h2 class="header1">6. Speicherung der erhobenen Daten
      </h2>
      <p class="text">
        Ihre Daten werden nur so lange gespeichert, wie es für die Bearbeitung Ihrer Reservierung erforderlich ist. Nach
        Abschluss der Reservierung und Ablauf etwaiger gesetzlicher Aufbewahrungsfristen werden die Daten
        gelöscht.<br><br>

      </p>
    </div>

    <div class="section">
      <h2 class="header1">7. Weitergabe der Daten
      </h2>
      <p class="text">
        Ihre Daten werden nicht an Dritte weitergegeben, es sei denn, dies ist zur Erfüllung Ihrer Reservierung
        erforderlich oder wir sind gesetzlich dazu verpflichtet.<br><br>
      </p>
    </div>

    <div class="section">
      <h2 class="header1">8. Ihre Rechte
        Als betroffene Person
      </h2>
      <p class="text">
        Als betroffene Person haben Sie folgende Rechte:<br><br>
        <b>Auskunftsrecht:</b> Sie haben das Recht, Auskunft über die von uns verarbeiteten personenbezogenen Daten zu
        erhalten.<br><br>
        <b>Berichtigungsrecht:</b> Sie können die Berichtigung unrichtiger oder unvollständiger Daten verlangen.<br><br>
        <b>Löschungsrecht:</b> Sie können die Löschung Ihrer personenbezogenen Daten verlangen, sofern keine
        gesetzlichen Aufbewahrungspflichten dem entgegenstehen.<br><br>
        <b>Einschränkungsrecht:</b> Sie können die Einschränkung der Verarbeitung Ihrer Daten verlangen.<br><br>
        <b>Widerspruchsrecht:</b> Sie haben das Recht, der Verarbeitung Ihrer Daten aus besonderen Gründen zu
        widersprechen.<br><br>
        <b>Datenübertragbarkeit:</b> Sie können verlangen, dass wir Ihnen Ihre Daten in einem strukturierten, gängigen
        und maschinenlesbaren Format bereitstellen.<br><br>
      </p>
    </div>

    <div class="section">
      <h2 class="header1">9. Kontakt für Datenschutzanfragen
      </h2>
      <p class="text">
        Für Fragen oder zur Geltendmachung Ihrer Rechte wenden Sie sich bitte an:<br><br>
        <b>Angelika Sawas</b><br><br>
        <b>Kais.Mansur@web.de</b><br><br>
        <b>01704618521</b><br><br>
        <b>Columbiastraße 19, 97688 Bad Kissingen</b><br><br>
      </p>
    </div>

    <div class="section">
      <h2 class="header1">10. Änderungen dieser Datenschutzerklärung
      </h2>
      <p class="text">
        Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf anzupassen, um rechtlichen oder
        tatsächlichen Änderungen Rechnung zu tragen. Die jeweils aktuelle Version ist auf unserer Website
        verfügbar.<br><br>
      </p>
    </div>

    <div class="section">
      <h2 class="header1">11. Stand der Datenschutzerklärung
      </h2>
      <h2 class="header1">11.12.2024
      </h2>
    </div>

  </div>
</template>
    
    <script>
    import { defineComponent } from '@vue/runtime-core'
    import { mapGetters, mapActions } from "vuex";
    
    export default defineComponent({
      setup() {
        
      }, data() {
        return {
      
            filter: ""
          }
        },
       components:{
       },
       computed: {
          ...mapGetters(["mainBestellung", "mainBahnen"]),
       },
       methods:{
           ...mapActions(["mflApi"]),

             
       }
    })
    </script>
    
    <style>
    
    .section{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 80%;
      border: solid black;
      background-color: rgb(212, 194, 88);
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .text{
      width: 80%;
      font-size: 16px;
      hyphens: auto; 
      text-align: justify
    }
    
    
    .input{
      width: 60%;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border: solid 2px black;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    
    
    
    </style>