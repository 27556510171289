<template>
    <div class="mainPage">
    <h1 class="site-header">Bowling Reservierungen</h1>

    <input type="date" class="input" v-model="this.mainMeta.datum" @change="updateList()"/>
   
    <div class="mainBahnen">

        <div class="singleBahn" v-for="bahn in this.mainMeta.bahnen" :key="bahn">

            
            <div class="buchung">
                <table class="buchungTable">
                    <tr>
                        <td>Bahn {{ bahn.bahn }}</td>
                    </tr>
                </table>
            </div>


            <div class="buchung" v-for="buchung in bahn.list" :key="buchung" @click="redirect(buchung)">
                <table class="buchungTable">
                    <tr>
                        <td>{{ buchung.name }}</td>
                    </tr>
                    <tr>
                        <td>{{ buchung.personen }}</td>
                    </tr>
                    <tr>
                        <td>Tel: {{ buchung.tel }}</td>
                    </tr>
                    <tr>
                        <td>{{ buchung.uhrzeit }} - {{ buchung.endzeit }}</td>
                    </tr>

                    <tr>
                        <td>{{ buchung.notiz }}</td>
                    </tr>
                </table>
            </div>


        </div>

        


    </div>


    
    </div>
    </template>
    
    <script>
    import { defineComponent } from '@vue/runtime-core'
    import { mapGetters, mapActions } from "vuex";
    
    export default defineComponent({
      setup() {
        
      }, data() {
        return {
      
         bahnen:[
            {bahn: 1, list: [{id: "11", bahn:1,  datum: "2024-11-11", stunden: 2 , name: "Hitler", tel: "183819", uhrzeit: "21:00", endzeit: "23:00", personen: 4, notiz: ""},
            {id: "22", bahn: 1, name: "Broly", datum: "2024-11-11",  stunden: 2, tel: "183819", uhrzeit: "16:00", endzeit: "18:00", personen: 2, notiz: "Geburtstag"},


            ]},
            {bahn: 2, list: []},
            {bahn: 3, list: []},
            {bahn: 4, list: []},
            {bahn: 5, list: []},
            {bahn: 6, list: []},
            {bahn: 7, list: []},
            {bahn: 8, list: []},
            {bahn: 9, list: []},
            {bahn: 10, list: []},
         ]
          }
        },
       components:{
       },
       computed: {
          ...mapGetters(["mainBestellung", "mainBahnen", "mainMeta"]),
       },
       methods:{
           ...mapActions(["mflApi"]),
        redirect(buchung){
            console.log(buchung)
            this.mainBestellung.name = buchung.name
            this.mainBestellung.datum = buchung.datum
            this.mainBestellung.tel = buchung.tel
            this.mainBestellung.start = buchung.uhrzeit
            this.mainBestellung.stunden = buchung.stunden
            this.mainBestellung.personen = buchung.personen
            this.mainBestellung.notiz = buchung.notiz
            this.mainBestellung.id = buchung.id
            this.mainBestellung.bahn.selected = buchung.bahn
            this.$router.push("buchung")
            console.log(this.mainBestellung)
        },
        updateList(){
            const data = {
            url: "getBahnen",
            val: 101
          }

          this.mflApi(data);
        }
    
       },
       created(){
        const data = {
            url: "getMeta",
            val: 100
          }

          this.mflApi(data);
       // console.log(this.bahnen);
       // console.log(this.mainMeta.bahnen);
      //  this.bahnen = this.mainMeta.bahnen;
       }
    })
    </script>
    
    <style>
    
 .mainBahnen{
    width: 120%;
    height: 500px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
 }


 .singleBahn{
    width: 150px;
    height: 100%;
    background-color: rgb(212, 194, 88);
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
 }

 .buchung{
width: 80%;
max-height: 150px;
background-color: black;
box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
margin-top: 5px;
margin-bottom: 5px;
 }

 .buchung:hover{
    transform: scale(1.05);
    cursor: pointer;
    background-color: white;
 }

 .buchungTable{
    color: rgb(212, 194, 88);
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
 }

 .buchungTable:hover{
    color: black;
 }
    
    </style>